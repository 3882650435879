// Gatsby supports TypeScript natively!
import React from 'react';
import { PageProps, Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Panel from '../components/panel';
import LinkOut from '../components/linkOut';
import TopPanel from '../components/topPanel';
import ButtonLink from '../components/buttonLink';

import HorizontalTitle from '../components/horizontalTitle';
import HorizontalButtonsContainer from '../components/horizontalButtonsContainer';
import VerticalButtonsContainer from '../components/verticalButtonsContainer';

import Map from '../images/Multi-Scale Integrated Cell (MuSIC) v1.png';
import styles from './pageStyles/index.module.css';
import pipeline from '../images/pipeline_overview.png';
import RF from '../images/GitHub_RandomForest.png';
import cells from '../images/cells.jpeg';


const contrastBackgroundColor = 'rgba(65,182,255, 0.25)';
const contrastTextColor = 'black';

const DonwloadData = (props: PageProps) => (
  <Layout>
    <SEO title='Tools' />
   
    <TopPanel>
      <HorizontalTitle title='Browse Tools' subtitle='U2OS Cell Map' />
    </TopPanel>

    <Panel>
      <a id='data'>
        <h2>Tools for cell map construction </h2>
      </a>
      <p>
      <div className={styles.logosContainer}>
          <img src={pipeline} />
      </div>
        <ul>          
          <li>
          Cell map map pipeline
          <ol>   This package is used to invoke steps of the cell map construction pipeline, including downloading data, generating embeddings, unsupervised co-embedding, and creating the final cell map hierarchy. </ol> 
          <ol>
            <LinkOut link='https://github.com/idekerlab/cellmaps_pipeline'> GitHub
             </LinkOut>
             &nbsp;
            &nbsp;
            {' '}
            <LinkOut link='https://cellmaps-pipeline.readthedocs.io/en/latest/'> Documentation
             </LinkOut>
          </ol>
          </li>
         </ul>
         <ul>          
          <li>
          Image downloader
          <ol>   This package downloads images from the Human Protein Atlas or other user-supplied links </ol> 
          <ol>
          <LinkOut link='https://github.com/idekerlab/cellmaps_imagedownloader'> GitHub
             </LinkOut>
             &nbsp;
            &nbsp;
            {' '}
            <LinkOut link='https://cellmaps-imagedownloader.readthedocs.io/en/latest/'> Documentation
             </LinkOut>
          </ol>
          </li>
         </ul>
         <ul>          
          <li>
          PPI downloader
          <ol>   This package loads in a protein-protein interaction network and prepares the data for embedding  </ol> 
          <ol>
          <LinkOut link='https://github.com/idekerlab/cellmaps_ppidownloader'> GitHub
             </LinkOut>
             &nbsp;
            &nbsp;
            {' '}
            <LinkOut link='https://cellmaps-ppidownloader.readthedocs.io/en/latest/'> Documentation
             </LinkOut>
          </ol>
          </li>
         </ul>
         <ul>          
          <li>
          Generate image embedding
          <ol>   This package creates an image embedding using the <LinkOut link='https://www.nature.com/articles/s41592-019-0658-6'> densenet</LinkOut> model (see Tools for image embedding section below)  </ol> 
          <ol>
          <LinkOut link='https://github.com/idekerlab/cellmaps_image_embedding'> GitHub
             </LinkOut>
             &nbsp;
            &nbsp;
            {' '}
            <LinkOut link='https://cellmaps-image-embedding.readthedocs.io/en/latest/'> Documentation
             </LinkOut>
          </ol>
          </li>
         </ul>
         <ul>          
          <li>
          Generate PPI embedding
          <ol>   This package creates an embedding for each protein in a protein-protein interaction netowrk using <LinkOut link='https://cellmaps-utils.readthedocs.io/en/latest/'> node2vec</LinkOut>  </ol> 
          <ol>
          <LinkOut link='https://github.com/idekerlab/cellmaps_ppi_embedding'> GitHub
             </LinkOut>
             &nbsp;
            &nbsp;
            {' '}
            <LinkOut link='https://cellmaps-ppi-embedding.readthedocs.io/en/latest/'> Documentation
             </LinkOut>
          </ol>
          </li>
         </ul>
         <ul>          
          <li>
          Co-embedding
          <ol>   This package uses unsupervised machine learning to integrate the image and PPI embeddings into a single co-embedding for each protein based on an implementation of <LinkOut link='https://github.com/AltschulerWu-Lab/MUSE'> MUSE</LinkOut>  </ol> 
          <ol>
          <LinkOut link='https://github.com/idekerlab/cellmaps_coembedding'> GitHub
             </LinkOut>
             &nbsp;
            &nbsp;
            {' '}
            <LinkOut link='https://cellmaps-coembedding.readthedocs.io/en/latest/'> Documentation
             </LinkOut>
          </ol>
          </li>
         </ul>
         <ul>          
          <li>
          Generate hierarchy
          <ol>   This package constructs a hierarchy using pan-resolution community detection with <LinkOut link='https://github.com/fanzheng10/HiDeF'> HiDeF</LinkOut>  </ol> 
          <ol>
          <LinkOut link='https://github.com/idekerlab/cellmaps_generate_hierarchy'> GitHub
             </LinkOut>
             &nbsp;
            &nbsp;
            {' '}
            <LinkOut link='https://cellmaps-generate-hierarchy.readthedocs.io/en/latest/'> Documentation
             </LinkOut>
          </ol>
          </li>
         </ul>
         <ul>          
          <li>
          Cell maps utils
          <ol>   This package contains utilities needed by the various tools </ol> 
          <ol>
          <LinkOut link='https://github.com/idekerlab/cellmaps_utils'> GitHub
             </LinkOut>
             &nbsp;
            &nbsp;
            {' '}
            <LinkOut link='https://cellmaps-utils.readthedocs.io/en/latest/'> Documentation
             </LinkOut>
          </ol>
          </li>
         </ul>
      </p>
    </Panel>
  </Layout>
);

export default DonwloadData;
